import React from "react";

import PageTitle from "../components/elements/PageTitle";
import Header from "../components/sections/Header";
import ContactSection from "../components/sections/ContactSection";
import Footer from "../components/sections/Footer";

const ContactsSePage = () => (
  <div className="bg-white niramit-regular">
    <PageTitle pageTitle={"Contact Axxtrans"} description={``} />
    <Header lang="se" />
    <ContactSection
      title="Kontakta AxxPay"
      description="Lämna din fråga med detta formulär och vi kommer att kontakta dig så snart som möjligt."
      subjectMail="Short contact"
      nameLabel="Förnamn"
      emailLabel="E-post"
      phoneLabel="Telefonnummer"
      messageLabel="Meddelande"
      submitButton="Skicka in"
      submitButtonSuccess="Skickats!"
      alertTitle="Thank you!"
      alertText="We`ve received your message. Someone from our team will contact you soon"
    />
    <Footer lang="se" />
  </div>
);

export default ContactsSePage;
